import {
  SUBMISSION_ENDPOINT,
  SUBMISSIONS_ENDPOINT,
  ENTITY_ENDPOINT,
  SUBMISSION_EMAIL_ENDPOINT,
  ADMIN_REPORT_ENDPOINT,
  JUROR_DASHBOARD_ENDPOINT,
  NATIONAL_RESULTS_ENDPOINT,
  SUBMIT_NOMINATIONS_ENDPOINT,
  NEXTPREV_SUBMISSIONS_ENDPOINT,
  CONSIDERATIONS_ENDPOINT,
  ALLSUBMISSIONS_ENDPOINT
} from './constants';
import { uploadFile, postAttachFile, patchAttachFile } from './media';

// Nomination API calls
const NominationControl = {
  getSubmission(id) {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    const URL = `${SUBMISSION_ENDPOINT}${id ? '/' + id : ''}`;

    return fetch(URL, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  createSubmission() {
    const options = {
      method: 'POST',
      credentials: 'include'
    };

    return fetch(`${SUBMISSION_ENDPOINT}/new`, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getSubmissions() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(SUBMISSIONS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getReport() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(ADMIN_REPORT_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getJurorNominations() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(JUROR_DASHBOARD_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getNationalResults() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(NATIONAL_RESULTS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getConsiderations() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(CONSIDERATIONS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getAllSubmissions() {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    return fetch(ALLSUBMISSIONS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  getNextPrevSubmissions(id, region, status) {
    const options = {
      method: 'GET',
      credentials: 'include'
    };

    const URL = `${NEXTPREV_SUBMISSIONS_ENDPOINT}/${id}/${region}/${status}`;

    return fetch(URL, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  postPatchSubmission(entity, data, method) {
    const options = {
      method,
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify([{ entity, data }])
    };

    return fetch(SUBMISSION_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  postPatchEntity(method, entity, data, id) {
    const options = {
      method,
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };

    const URL = `${ENTITY_ENDPOINT}/${entity}${id ? `/${id}` : ''}`;

    return fetch(URL, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  deleteEntity(entity, id) {
    const options = {
      method: 'DELETE',
      credentials: 'include'
    };

    const URL = `${ENTITY_ENDPOINT}/${entity}/${id}`;

    return fetch(URL, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  sendSubmissionEmail(id, lang) {
    const options = {
      method: 'post',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, lang })
    };

    return fetch(SUBMISSION_EMAIL_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  submitNominations(region) {
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ region })
    };

    return fetch(SUBMIT_NOMINATIONS_ENDPOINT, options)
      .then((res) => res.json())
      .then((resJson) => resJson)
      .catch((error) => console.error(error));
  },
  /**
   * uploadAndAttachImage
   * @param {*} e form file event
   * @param {*} type type of entity to REST endpoint
   * @param {*} entity the json data to POST
   * @returns
   */
  uploadAndAttachImage: async (e, entity = {}) => {
    const file = await uploadFile(e, entity);
    if (file.message !== 'success') return false;
    const { id } = file.attachment;
    const object = await postAttachFile(id, 'image', entity);
    if (!object.id) return false;
    object.file = file;
    return object;
  },
  /**
   * uploadAndAttachNomination
   * @param {*} e form file event
   * @param {*} field expects a string matching the column name
   * @returns
   */
  uploadAndAttachNomination: async (e, entityId, field) => {
    const file = await uploadFile(e);

    if (file.message !== 'success') return false;
    const { id } = file.attachment;
    const object = await patchAttachFile(entityId, id, field);
    if (!object.success) return false;
    object.file = file;
    return object;
  },
  /**
   * Just upload the file and return the response
   * @param {*} e
   */
  uploadFile: async (e) => {
    const file = await uploadFile(e);

    if (file.message !== 'success') return false;

    return file.attachment;
  }
};

export { NominationControl };
