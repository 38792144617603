import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import { differenceInDays } from 'date-fns';
import ArtistCollective from '../../global/Artist/ArtistCollective';
import MediaUploadModal from '../../global/MediaUploadModal/MediaUploadModal';
import ConfirmDeleteModal from '../../global/ConfirmDeleteModal';
import { useAuth } from '../../../utils/auth';
import { NominationControl } from '../../../utils/nomination';
import { AdminControl } from '../../../utils/admin';
import 'react-image-lightbox/style.css';
import '../../../assets/scss/JurorDashboard.scss';

const ViewNomination = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [jurorRegion, setJurorRegion] = useState(null);
  const [artistData, setArtistData] = useState(null);
  const [nominatorData, setNominatorData] = useState(null);
  const [digitalImagesData, setDigitalImagesData] = useState(null);
  const [mediaWorksData, setMediaWorksData] = useState(null);
  const [score, setScore] = useState(0);
  const [status, setStatus] = useState(0);
  const [statusHistory, setStatusHistory] = useState(null);
  const [winner, setWinner] = useState(null);
  const [longListOpen, setLongListOpen] = useState(null);
  const [shortListOpen, setShortListOpen] = useState(null);
  const [longList, setLongList] = useState(null);
  const [shortList, setShortList] = useState(null);
  const [note, setNote] = useState('');
  const [savingNote, setSavingNote] = useState(false);
  const [chairNote, setChairNote] = useState('');
  const [savingChairNote, setSavingChairNote] = useState(false);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  
  const onModalClose = () => setModalOpen(false);
  
  const handleNoteChange = (e) => setNote(e.target.value);
  const handleChairNoteChange = (e) => setChairNote(e.target.value);

  const handleNominationChange = async (key, value, e) => {
    e?.preventDefault();

    if (key === 'note') setSavingNote(true);
    if (key === 'chairNote') setSavingChairNote(true);

    const updateNomination = await NominationControl.postPatchEntity(
      'PATCH',
      'submission',
      {
        [key]: value
      },
      id
    );
    if (!updateNomination) return false;

    if (key === 'score') setScore(value);
    if (key === 'status') setStatus(value);

    setTimeout(() => {
      if (key === 'note') setSavingNote(false);
      if (key === 'chairNote') setSavingChairNote(false);
    }, 2000);
  };

  const handleNominationChangeToggle = async (currentValue, key, matchValue, returnValue) => {

    let options = {};

    if (currentValue == matchValue) {
        // to catch scenarios where there may not be a return value
        const restoreValue = returnValue || 'submitted';
        // we're already toggled, un-toggle
        options = {
          [`${key}_history`]: null,
          [key]: restoreValue,
        };
    } else {
      // we're not toggled, toggle
      options = {
        [`${key}_history`]: currentValue,
        [key]: matchValue,
      };
    }

    // Run operation
    const updateNomination = await NominationControl.postPatchEntity(
      'PATCH',
      'submission',
      options,
      id
    );

    if (key === 'status') {
      setStatus(options[key]);
      setStatusHistory(options[`${key}_history`]);
    }

    if (!updateNomination) return false;
  }
    
  const handleDeleteSubmission = async () => {
    await Promise.all(
      digitalImagesData.images?.map(async (i) => {
        return await NominationControl.deleteEntity('image', i.id);
      })
    );
    await Promise.all(
      mediaWorksData.media?.map(async (m) => {
        return await NominationControl.deleteEntity('media', m.id);
      })
    );
    await Promise.all(
      artistData.collective?.map(async (artist) => {
        return await NominationControl.deleteEntity('artist', artist.id);
      })
    );
  
    if (artistData.bio)
      await NominationControl.deleteEntity('file', artistData.bio.id);
    if (artistData.cv)
      await NominationControl.deleteEntity('file', artistData.cv.id);
    if (artistData.headshot)
      await NominationControl.deleteEntity('file', artistData.headshot.id);
    if (nominatorData.letter)
      await NominationControl.deleteEntity('file', nominatorData.letter.id);
    
    await NominationControl.deleteEntity('artist', artistData.data.id);
    await NominationControl.deleteEntity('collective', artistData.collectiveId);
    await NominationControl.deleteEntity('submission', id);
  
    setModalOpen(false);
    navigate(t('routes:national-results'));
  };

  useEffect(() => {
    NominationControl.getSubmission(id).then(({ jurorRegion, submission }) => {
      if (
        submission.status === 'in progress' ||
        submission.status === null ||
        submission.error
      ) {
        navigate('/');
      } else if (submission.id) {
        const { primary, secondary } = getArtists(submission);

        NominationControl.getNextPrevSubmissions(id, primary.region, submission.status).then((submissionIds) => {
          const position = submissionIds.findIndex(s => s === id);
          const previous = submissionIds[position-1] || null;
          setPrevious(previous);
          setNext(submissionIds[position+1]);
        });

        setArtistData({
          data: primary,
          collective: secondary,
          cv: submission.cv,
          bio: submission.bio,
          headshot: submission.headshot,
          collective_name: submission.collective_name,
          collectiveId: submission.collectiveId
        });

        setNominatorData({
          nominator_first_name: submission.nominator_first_name,
          nominator_last_name: submission.nominator_last_name,
          category: submission.category,
          category_organization: submission.category_organization,
          letter: submission.letter
        });

        setDigitalImagesData({
          images: submission.images
        });

        submission.images.map((image) => (
          setImages(images => [...images, image.file.path])
        ));

        setMediaWorksData({
          media: submission.mediaWork
        });

        setJurorRegion(jurorRegion);
        setScore(submission.score);
        setStatus(submission.status);
        setStatusHistory(submission.status_history);
        setNote(submission.note || '');
        setChairNote(submission.chairNote || '');

        // Get the app settings and lock anything needed
        AdminControl.getSettings().then(({ winner, settings }) => {
          setWinner(winner);

          // Check for the current region, and if there are list lock overrides
          let longLocked, shortLocked;
          if (primary.region === 'Ontario') {
            longLocked = settings.lockLongOntario;
            shortLocked = settings.lockShortOntario;
          } else if (primary.region === 'Quebec') {
            longLocked = settings.lockLongQuebec;
            shortLocked = settings.lockShortQuebec;
          } else if (primary.region === 'Atlantic') {
            longLocked = settings.lockLongAtlantic;
            shortLocked = settings.lockShortAtlantic;
          } else if (primary.region === 'Prairies & North') {
            longLocked = settings.lockLongPrairies;
            shortLocked = settings.lockShortPrairies;
          } else if (primary.region === 'Prairies') {
            longLocked = settings.lockLongPrairies2;
            shortLocked = settings.lockShortPrairies2;
          } else if (primary.region === 'North') {
            longLocked = settings.lockLongNorth;
            shortLocked = settings.lockShortNorth;
          } else if (primary.region === 'Circumpolar') {
            longLocked = settings.lockLongCircumpolar;
            shortLocked = settings.lockShortCircumpolar;
          } else if (primary.region === 'West Coast & Yukon') {
            longLocked = settings.lockLongWestCoast;
            shortLocked = settings.lockShortWestCoast;
          }

          if (longLocked) {
            setLongListOpen(false);
          } else {
            const longDifference = differenceInDays(
              new Date(settings.enableLongList),
              new Date(settings.currentDate)
            );
            if (longDifference > 0) {
              setLongListOpen(longDifference);
            } else {
              setLongListOpen(true);
            }
          }

          if (shortLocked) {
            setShortListOpen(false);
          } else {
            const shortDifference = differenceInDays(
              new Date(settings.enableShortList),
              new Date(settings.currentDate)
            );
            if (shortDifference > 0) {
              setShortListOpen(shortDifference);
            } else {
              setShortListOpen(true);
            }
          }
        });

        // Get the regional nominations and filter for long-list / short-list count
        NominationControl.getJurorNominations().then(nominations => {
          if (nominations.data) {
            // Set all nominations
            const formatNominations = nominations.data.map((n) => ({
              ...n,
              artist: `${n.collective[0].artists[0].first_name} ${n.collective[0].artists[0].last_name}`
            }));

            // Long List
            const filterLongList = formatNominations.filter(
              (n) => n.status === 'long-list'
            );
            setLongList(filterLongList);

            // Short List
            const filterShortList = formatNominations.filter(
              (n) => n.status === 'short-list'
            );
            setShortList(filterShortList);
          }
        });
        
      }
    });
  }, [id]);

  let shortListDisabled = !shortListOpen;
  if (shortListOpen === false || (shortList?.length && shortList.length < 1)) {
    shortListDisabled = 1;
  }
  if (status === 'short-list' || ['head-juror', 'admin'].includes(auth?.role)) {
    shortListDisabled = 0;
  }
  let longListDisabled = !longListOpen;
  if (longListOpen === false || (longList?.length && longList.length < 5)) {
    longListDisabled = 1;
  }
  if (status === 'long-list' || ['head-juror', 'admin'].includes(auth?.role)) {
    longListDisabled = 0;
  }

  let dismissDisabled = 1;
  if (['head-juror', 'admin'].includes(auth?.role)) {
    dismissDisabled = 0;
  }

  const Sidebar = ({ artistData, jurorRegion }) => {
    return (
      <div className="col-12 col-lg-3 offset-lg-1 pt-2 mb-5 mb-lg-0">
        {artistData.headshot?.path &&
          <div>
            <div className="card col-12">
              <div className="card-body text-center bg-white">
                <Link to={artistData.headshot.path} target="_blank">
                  <img src={artistData.headshot.path} alt="" />
                </Link>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                  {artistData.headshot.name}
                </h6>
                <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                  {t('nomination.step1.credit')}: {artistData.data.headshotCredit}
                </h6>
              </div>
            </div>
          </div>
        }

        <h4 className="mt-4 text-center">
          {artistData.data.first_name} {artistData.data.last_name}
        </h4>
        {(auth?.role === 'regional-juror' && artistData.data.region === jurorRegion) || ['head-juror', 'admin'].includes(auth?.role) ?
          <>
            <div className="rating" style={{ marginTop: 30 }}>
              <span
                className={`${score > 0 ? 'active' : ''} ${![ 'regional-juror', 'head-juror', 'admin'].includes(auth?.role) ? 'disabled' : ''} ratingCircle`}
                onClick={() => handleNominationChange('score', 1)}
              ></span>
              <span
                className={`${score > 1 ? 'active' : ''} ${![ 'regional-juror', 'head-juror', 'admin'].includes(auth?.role) ? 'disabled' : ''} ratingCircle`}
                onClick={() => handleNominationChange('score', 2)}
              ></span>
              <span
                className={`${score > 2 ? 'active' : ''} ${![ 'regional-juror', 'head-juror', 'admin'].includes(auth?.role) ? 'disabled' : ''} ratingCircle`}
                onClick={() => handleNominationChange('score', 3)}
              ></span>
              <span
                className={`${score > 3 ? 'active' : ''} ${![ 'regional-juror', 'head-juror', 'admin'].includes(auth?.role) ? 'disabled' : ''} ratingCircle`}
                onClick={() => handleNominationChange('score', 4)}
              ></span>
              <span
                className={`${score > 4 ? 'active' : ''} ${![ 'regional-juror', 'head-juror', 'admin'].includes(auth?.role) ? 'disabled' : ''} ratingCircle`}
                onClick={() => handleNominationChange('score', 5)}
              ></span>
            </div>

            <div className="sidebarPosition mt-4">
              {(longList?.length && longList.length >= 5) ? <div className={'active'}><p>{t('juror.error1')}</p></div> : <></>}
              {(shortList?.length && shortList.length >= 1) ? <div className={'active'}><p>{t('juror.error2')}</p></div> : <></>}
              {!dismissDisabled && (<button
                className={`${status === 'dismiss' ? 'active' : ''} btn btn-lg positionBtn`}
                disabled={dismissDisabled}
                onClick={() => handleNominationChangeToggle(status, 'status', 'dismiss', statusHistory)}
              >{status === 'dismiss' ? `${t('juror.undo')} ${ t('juror.dismiss')}` : t('juror.dismiss')}</button>
              )}
              <button
                className={`${status === 'consider' ? 'active' : ''} btn btn-lg positionBtn`}
                disabled={!['regional-juror', 'head-juror', 'admin'].includes(auth?.role)}
                /* onClick={() => handleNominationChange('status', 'consider')} */
                onClick={() => handleNominationChangeToggle(status, 'status', 'consider', statusHistory)}
              >{status === 'consider' ? `${t('juror.undo')} ${ t('juror.consider')}` : t('juror.consider')}</button>
              <button
                className={`${status === 'long-list' ? 'active' : ''} btn btn-lg positionBtn`}
                disabled={longListDisabled}
                title={(longList?.length && longList.length >= 5) ? t('juror.error1') : ''}
                /* onClick={() => handleNominationChange('status', 'long-list')} */
                onClick={() => handleNominationChangeToggle(status, 'status', 'long-list', statusHistory)}
              >{status === 'long-list' ? `${t('juror.undo')} ${ t('juror.longList')}` : t('juror.longList')}</button>
              <button
                className={`${status === 'short-list' ? 'active' : ''} btn btn-lg positionBtn`}
                disabled={shortListDisabled}
                /* onClick={() => handleNominationChange('status', 'short-list')} */
                title={(shortList?.length && shortList.length >= 1) ? t('juror.error2') : ''}
                onClick={() => handleNominationChangeToggle(status, 'status', 'short-list', statusHistory)}
              >{status === 'short-list' ? `${t('juror.undo')} ${ t('juror.shortList')}` : t('juror.shortList')}</button>
            </div>
          </>
        : <></>}

        {!winner && ['head-juror', 'admin'].includes(auth?.role) &&
          <div className="position mt-4" style={{ justifyContent: 'flex-start' }}>
            <button
              className={`${status === 'winner' ? 'active' : ''} btn btn-lg positionBtn`}
              onClick={() => handleNominationChange('status', 'winner')}
            >{t('juror.pickAsWinner')}</button>
          </div>
        }
        
        {!winner && auth?.role === 'admin' &&
          <div className="position mt-4" style={{ justifyContent: 'flex-start' }}>
            <button
              className="btn btn-lg me-4"
              onClick={() => navigate(`${t('routes:step1')}/${id}`)}
            >{t('home.edit')}</button>
            <button
              className="btn btn-lg btn-grey"
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(true);
              }}
            >{t('home.delete')}</button>
          </div>
        }
      </div>
    );
  };

  return (
    <>
      <ConfirmDeleteModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSubmit={handleDeleteSubmission}
        type="submission"
      />
    
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3 pb-5">
            {!artistData ? (
              <div>Loading...</div>
            ) : (
              <>
                <h1 className="title mb-5">
                  {artistData.data.first_name} {artistData.data.last_name} ({status})
                </h1>
                <Tabs>
                  <TabList className="react-tabs__tab-list nationalTabs">
                    <li className="react-tabs__tab" onClick={() => navigate(-1)}>
                      <span className="tabTitle">
                        <i
                          className="fa fa-chevron-left"
                          style={{ fontSize: 14, margin: '0 0.5em 0 0' }}
                        ></i>
                        {t('juror.back')}
                      </span>
                    </li>
                    <Tab>
                      <span className="tabTitle">{t('juror.personalInfo')}</span>
                    </Tab>
                    <Tab>
                      <span className="tabTitle">{t('nomination.header.step2')}</span>
                    </Tab>
                    <Tab>
                      <span className="tabTitle">{t('juror.images')}</span>
                    </Tab>
                    <Tab>
                      <span className="tabTitle">{t('nomination.header.step4')}</span>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <div className="row flex-column-reverse flex-lg-row">
                      <div className="col-12 col-lg-8">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              {t('nomination.step1.heading2')}
                            </label>

                            <p>
                              {artistData.data.address_1}<br />
                              {artistData.data.address_2} {artistData.data.address_rr}
                              {(artistData.data.address_2 || artistData.data.address_rr) && <br />}
                              {artistData.data.address_city}, {artistData.data.address_province}<br />
                              {artistData.data.address_pc}<br />
                              <span className="fw-bold">{t('nomination.step1.region.title')}:</span> {artistData.data.region}
                            </p>
                            <a href={`mailto:${artistData.data.email}`}>
                              {artistData.data.email}
                            </a><br />
                            <a href={`tel:+${artistData.data.phone}`}>
                              {artistData.data.phone}
                            </a><br />
                            {artistData.data.alternate_phone &&
                              <a href={`tel:+${artistData.data.alternate_phone}`}>
                                {artistData.data.alternate_phone}
                              </a>
                            }
                          </div>
                          <div className="col-12 col-md-6 mt-5 mt-md-0">
                            {artistData.bio?.name &&
                              <div>
                                <label className="form-label">
                                  {artistData.collective?.length > 1 && i18n.language !== 'fr'
                                    ? t('nomination.step1.collective')
                                    : ''}{' '}
                                  {t('nomination.step1.heading5')}{' '}
                                  {artistData.collective?.length > 1 && i18n.language === 'fr'
                                    ? t('nomination.step1.collective')
                                    : ''}
                                </label>
                                <div className="card col-12">
                                  <div className="card-body bg-white">
                                    <Link to={artistData.bio.path} target="_blank">
                                      <i className="fa fa-file-o text-center"></i>
                                    </Link>
                                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                                      <Link to={artistData.bio.path} target="_blank">
                                        {artistData.bio.name}
                                      </Link>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            }

                            {artistData.cv?.name &&
                              <div>
                                <label className="form-label mt-5">
                                  {artistData.collective?.length > 1 && i18n.language !== 'fr'
                                    ? t('nomination.step1.collective')
                                    : ''}{' '}
                                  {t('nomination.step1.heading6')}{' '}
                                  {artistData.collective?.length > 1 && i18n.language === 'fr'
                                    ? t('nomination.step1.collective')
                                    : ''}
                                </label>
                                <div className="card col-12">
                                  <div className="card-body bg-white">
                                    <Link to={artistData.cv.path} target="_blank">
                                      <i className="fa fa-file-o text-center"></i>
                                    </Link>
                                    <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                                      <Link to={artistData.cv.path} target="_blank">
                                        {artistData.cv.name}
                                      </Link>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        {artistData.collective.length > 0 &&
                          <div className="row mt-0 mt-md-5">
                            <div className="col-12">
                              <label className="form-label mt-5 mt-md-4">
                                {t('juror.collectiveMembers')}
                              </label>
                              <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                                  {artistData.collective_name}
                              </h6>
                              <ArtistCollective collective={artistData.collective} view={true} />
                            </div>
                          </div>
                        }
                        <div className="row mt-5">
                          <form className="col-12" onSubmit={(e) => handleNominationChange('note', note, e)}>
                            <div className="form-group">
                              <label htmlFor="note">{t('juror.jurorsNotes')} <em>({t('juror.publicNotes')})</em></label>
                              <textarea className="form-control" id="note" value={note} onChange={handleNoteChange} rows="4" disabled={auth && auth.role === 'regional-juror' && artistData.data.region === jurorRegion ? 0 : 1}></textarea>
                            </div>
                            {auth && auth?.role === 'regional-juror' && artistData.data.region === jurorRegion && (
                              <button type="submit" className="btn btn-lg mt-3" disabled={savingNote}>
                                {savingNote ? t('nomination.footer.saving') : t('juror.saveNote')}
                              </button>
                            )}
                          </form>
                        </div>
                        <div className="row mt-5">
                          <form className="col-12" onSubmit={(e) => handleNominationChange('chairNote', chairNote, e)}>
                            <div className="form-group">
                              <label htmlFor="chairNote">{t('juror.chairsNotes')} <em>({t('juror.publicNotes')})</em></label>
                              <textarea className="form-control" id="chairNote" value={chairNote} onChange={handleChairNoteChange} rows="4" disabled={auth && auth.role === 'head-juror' ? 0 : 1}></textarea>
                            </div>
                            {auth && auth.role === 'head-juror' && (
                              <button type="submit" className="btn btn-lg mt-3" disabled={savingChairNote}>
                                {savingChairNote ? t('nomination.footer.saving') : t('juror.saveNote')}
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                      <Sidebar artistData={artistData} jurorRegion={jurorRegion} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row flex-column-reverse flex-lg-row">
                      <div className="col-12 col-lg-8">
                        {nominatorData &&
                          <>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <h5 className="mb-2 mt-2">
                                  {nominatorData.nominator_first_name} {nominatorData.nominator_last_name}
                                </h5>
                                <h6 className="mb-2 mt-1">
                                  <span className="fw-bold">{t('nomination.step2.heading3')}:</span> {nominatorData.category}
                                </h6>
                                {nominatorData.category_organization &&
                                  <h6 className="mb-2 mt-1">
                                    <span className="fw-bold">{t('nomination.step2.category.placeholder')}:</span> {nominatorData.category_organization}
                                  </h6>
                                }
                              </div>
                              {nominatorData.letter?.name &&
                                <div className="col-12 col-md-6 mt-3 mt-md-0">
                                  <label className="form-label">
                                    {t('nomination.step2.heading2')}
                                  </label>

                                  <div className="card col-12">
                                    <div className="card-body bg-white">
                                      <Link to={nominatorData.letter.path} target="_blank">
                                        <i className="fa fa-file-o text-center"></i>
                                      </Link>
                                      <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                                        <Link to={nominatorData.letter.path} target="_blank">
                                          {nominatorData.letter.name}
                                        </Link>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        }
                      </div>
                      <Sidebar artistData={artistData} jurorRegion={jurorRegion} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {!!isOpen && images && (
                      <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                          images[
                            (photoIndex + images.length - 1) % images.length
                          ]
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex + images.length - 1) % images.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % images.length)
                        }
                      />
                    )}

                    <div className="row images flex-column-reverse flex-lg-row">
                      <div className="col-12 col-lg-8">
                        <div className="row">
                          {digitalImagesData?.images && digitalImagesData?.images.length ? digitalImagesData.images.map((image, i) => (
                            <div className="col-12 col-md-6 p-2" key={image.fileId}>
                              <div className="card">
                                <div className="card-body bg-white">
                                  <div
                                    className="view"
                                    onClick={() => {
                                      setPhotoIndex(i);
                                      setIsOpen(true);
                                    }}
                                  >
                                    <div className="view-overlay">
                                      <i className="fa fa-search-plus"></i>
                                    </div>
                                    <img
                                      className="card-img"
                                      src={image?.file?.path}
                                      alt=""
                                    />
                                  </div>
                                  <h5 className="mb-2 mt-4">
                                    <span className="fw-bold">{image.title} </span>
                                    {image.year ? image.year.split("-")[0] : null}
                                  </h5>
                                  <h6 className="text-muted fw-light mb-0">
                                    {image.material}, {image.dimensions}
                                  </h6>
                                  <h6 className="text-muted fw-light mb-0">
                                    {t('nomination.components.credits')}: {image.credits}
                                  </h6>
                                  <Link to={image?.file?.path} target="_blank">
                                    <i className="fa fa-cloud-download"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )) : null}
                        </div>
                      </div>
                      <Sidebar artistData={artistData} jurorRegion={jurorRegion} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row flex-column-reverse flex-lg-row">
                      <div className="col-12 col-lg-8" style={{ marginTop: '-1.5rem' }}>
                        <MediaUploadModal media={mediaWorksData?.media} view={true} />
                      </div>
                      <Sidebar artistData={artistData} jurorRegion={jurorRegion} />
                    </div>
                  </TabPanel>
                </Tabs>
              </>
            )}
          </div>
        </div>
      </div>

      <footer className="actions mt-3">
        <div className="container">
          <div className="footer-btn-wrap d-flex">
            <Link
              className={`${!previous ? 'disabled' : ''} btn btn-lg submit`}
              style={{ marginRight: 'auto' }}
              to={`/view/${previous}`}
            >
              <i
                className="fa fa-chevron-left"
                style={{ margin: '0 0.5em 0 0' }}
              ></i>
              {t('juror.previousArtist')}
            </Link>
            <Link
              className={`${!next ? 'disabled' : ''} btn btn-lg submit`}
              disabled={!next}
              to={`/view/${next}`}
            >
              {t('juror.nextArtist')}<i className="fa fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ViewNomination;

const getArtists = (submission) => {
  if (!Array.isArray(submission.artists)) {
    return { primary: {}, secondary: [] };
  }
  // sort by date created, just in case
  const sorted = submission.artists
    .sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    .reverse();
  // split into primary and secondary
  const hasSecondary = sorted.length > 1;
  const primary = { ...sorted.shift() };
  const secondary = hasSecondary ? sorted : [];

  return { primary, secondary };
};
