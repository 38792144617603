import React from 'react';
import { Alert, FloatingLabel } from 'react-bootstrap';
import Modal from 'react-modal';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { NominationControl } from '../../../utils/nomination';
import { handleApiErrors } from '../../../utils/error';
import DeleteButton from '../../global/images/DeleteButton';
import EditButton from '../../global/EditButton';

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '600px',
      maxWidth: '100%',
      maxHeight: '96%',
      padding: '40px 20px'
    },
};


const ArtistCollective = ({collective, collectiveId, button, callback, view}) => {

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [apiError, setApiError] = React.useState(false);
    const [removeError, setRemoveError] = React.useState(false);
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(t('nomination.step1.errors.firstName'))
            .max(30, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.firstName')).replace("{{length}}", 30))
            .nullable(),
        last_name: Yup.string()
            .required(t('nomination.step1.errors.lastName'))
            .max(30, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.lastName')).replace("{{length}}", 30))
            .nullable(),
        address_1: Yup.string()
            .required(t('nomination.step1.errors.address'))
            .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.address1')).replace("{{length}}", 40))
            .nullable(),
        address_2: Yup.string()
            .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.address2')).replace("{{length}}", 40))
            .nullable(),
        address_rr: Yup.string()
            .max(40, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.addressrr')).replace("{{length}}", 40))
            .nullable(),
        address_city: Yup.string()
            .required(t('nomination.step1.errors.city'))
            .max(30, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.city')).replace("{{length}}", 30))
            .nullable(),
        address_province: Yup.string()
            .required(t('nomination.step1.errors.province'))
            .nullable(),
        address_pc: Yup.string()
            .required(t('nomination.step1.errors.postalCode'))
            .max(7, t('errors.textfieldLength').replace("{{field_name}}", t('nomination.step1.postalCode')).replace("{{length}}", 7))
            .nullable(),
        id: Yup.string().nullable(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm(formOptions);

    const onSubmit = async (data) => {
        data.collectiveId = collectiveId;
        let result = false;
        if (!data.id) {
            delete data.id;
            result = await NominationControl.postPatchSubmission('artist', data, 'POST');
        } else {
            result = await NominationControl.postPatchSubmission('artist', data, 'PATCH');
        }

        const errors = handleApiErrors(result, setApiError);

        if (!errors) {
            callback();
            setModalOpen(false);
            reset();
        }
    };

    const handleModalToggle = () => {
        setRemoveError(false);
        setApiError(false);
        reset({
            first_name: null,
            last_name: null,
            id: null,
            address_2: null,
            address_1: null,
            address_rr: null,
            address_city: null,
            address_province: null,
            address_pc: null
        });
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        callback();
        reset();
    }

    const handleRemoveMember = async (id) => {
        setRemoveError(false);
        let result = false;
        result = await NominationControl.postPatchEntity('DELETE', 'artist',
            {},
            id)
        const errors = handleApiErrors(result, setRemoveError);
        if (!errors) {
            callback();
        }
    }

    const handleEditModal = (artist) => {
        setRemoveError(false);
        setApiError(false);
        reset({
            first_name: artist.first_name,
            last_name: artist.last_name,
            address_2: artist.address_2,
            address_1: artist.address_1,
            address_rr: artist.address_rr,
            address_city: artist.address_city,
            address_province: artist.address_province,
            address_pc: artist.address_pc,
            id: artist.id})
        setModalOpen(true);
    }

    return (
        <div>
            {removeError && (
                <div className="row bg-light">
                    <div className="col-12 p-4 pb-0">
                        <Alert key={`remove_Error`} variant="danger">
                            <p>{removeError}</p>
                        </Alert>
                    </div>
                </div>
            )}
            <div className="row">
            {collective && collective.length ? collective.map((artist) => (
                <div className="col col-12 col-sm-6 col-md-4 p-2" key={artist.id}>
                    <div className="card">
                    <div className="card-body bg-white">
                    { !view &&
                      <>
                        <DeleteButton
                          action={() => handleRemoveMember(artist.id)}
                        />
                        <EditButton
                          action={() => handleEditModal(artist)}
                        />
                      </>
                    }
                        <h5 className="text-muted card-subtitle mb-2 fw-light mt-1 fw-bold small">
                        {artist.first_name} {artist.last_name}
                        </h5>
                        <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                        {artist.address_2} {artist.address_1} {artist.address_rr}
                        </h6>
                        <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                        {artist.address_city} {artist.address_province}
                        </h6>
                        <h6 className="text-muted card-subtitle mb-2 fw-light mt-1">
                        {artist.address_pc}
                        </h6>
                    </div>
                    </div>
                </div>
                )) : null}
            </div>
            <div className="inline"
                onClick={handleModalToggle}
            >
                {button}
            </div>
            <Modal
                isOpen={modalOpen}
                onAfterOpen={() => null}
                onRequestClose={handleModalClose}
                style={customStyles}
            >
                <button
                    className="btn btn-light btn-sm text-end rounded-circle btn-close position-absolute top-0 end-0"
                    type="button"
                    data-bs-target="#modal-1"
                    data-bs-toggle="modal"
                    onClick={handleModalClose}
                ></button>

                <div className="modal-header">
                  <h3 className="modal-title mt-0">{t('nomination.step1.add1')}</h3>
                </div>

                    <form id="artist_collective_form">
                        {Object.keys(errors).length !== 0 && (
                            <div className="row bg-light">
                                <div className="col-12 p-4 pb-0">
                                    {Object.keys(errors).map((field) => (
                                        <Alert key={field} variant="danger">
                                            <p>{errors[field].message}</p>
                                        </Alert>
                                    ))}
                                </div>
                            </div>
                        )}
                        {apiError && (
                            <div className="row bg-light">
                                <div className="col-12 p-4 pb-0">
                                    {apiError.map((field) => (
                                        <Alert key={field} variant="danger">
                                            <p>{field}</p>
                                        </Alert>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="col-12 p-1 mt-4">
                            <div className="input-group">
                                <FloatingLabel
                                    controlId="first_name"
                                    label={`${t('nomination.step1.firstName')} *`}
                                    className="mr-1"
                                >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('nomination.step1.firstName')}
                                        maxLength="30"
                                        {...register('first_name')}
                                    />
                                </FloatingLabel>
                                <FloatingLabel controlId="last_name" label={`${t('nomination.step1.lastName')} *`}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('nomination.step1.lastName')}
                                        maxLength="30"
                                        {...register('last_name')}
                                    />
                                </FloatingLabel>
                            </div>
                        </div>
                        <div className="col-12 p-1">
                            <div className="input-group">
                                <FloatingLabel
                                controlId="address_1"
                                label={`${t('nomination.step1.address1')} *`}
                                >
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.step1.address1')}
                                    maxLength="40"
                                    {...register('address_1')}
                                />
                                </FloatingLabel>
                            </div>
                        </div>
                        <div className="col-12 p-1">
                            <div className="input-group">
                                <FloatingLabel
                                    controlId="address_2"
                                    label={t('nomination.step1.address2')}
                                    className="mr-1"
                                    >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('nomination.step1.address2')}
                                        maxLength="40"
                                        {...register('address_2')}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="address_rr"
                                    label={t('nomination.step1.addressrr')}
                                    >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('nomination.step1.addressrr')}
                                        maxLength="40"
                                        {...register('address_rr')}
                                    />
                                </FloatingLabel>
                            </div>
                        </div>
                        <div className="col-12 p-1">
                            <div className="input-group">
                                <FloatingLabel
                                controlId="address_city"
                                label={`${t('nomination.step1.city')} *`}
                                >
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('nomination.step1.city')}
                                    maxLength="30"
                                    {...register('address_city')}
                                />
                                </FloatingLabel>
                            </div>
                        </div>
                        <div className="col-12 p-1">
                            <div className="input-group">
                                <FloatingLabel
                                controlId="address_province"
                                label={`${t('nomination.step1.province')} *`}
                                className="mr-1"
                                >
                                    <select
                                        {...register('address_province')}
                                        className="form-select"
                                    >
                                        <option value="">{t('nomination.step1.province')}</option>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">British Columbia</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">Newfoundland and Labrador</option>
                                        <option value="NS">Nova Scotia</option>
                                        <option value="NT">Northwest Territories</option>
                                        <option value="NU">Nunavut</option>
                                        <option value="ON">Ontario</option>
                                        <option value="PE">Prince Edward Island</option>
                                        <option value="QC">Quebec</option>
                                        <option value="SK">Saskatchewan</option>
                                        <option value="YT">Yukon</option>
                                    </select>
                                </FloatingLabel>
                                <FloatingLabel controlId="address_pc" label={`${t('nomination.step1.postalCode')} *`}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('nomination.step1.postalCode')}
                                        maxLength="7"
                                        {...register('address_pc')}
                                    />
                                </FloatingLabel>
                            </div>
                        </div>
                        <button
                            className="btn btn-lg mt-3 mb-3"
                            form="artist_collective_form"
                            onClick={handleSubmit(onSubmit)}
                            style={{float: 'right'}}
                        >
                            {t('nomination.components.saveArtist')}
                            <i className="fa fa-chevron-right"></i>
                        </button>
                        <div style={{clear:'right'}} />
                    </form>
            </Modal>
        </div>
    )
}

export default ArtistCollective;
