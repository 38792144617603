import { useEffect, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import ToggleSwitch from '../../global/ToggleSwitch';
import DropDown from '../../global/DropDown';
import Button from '../../global/Button';
import { AdminControl } from '../../../utils/admin';
import 'react-datepicker/dist/react-datepicker.css';

const AdminSettings = () => {
  const { t } = useTranslation();
  const [savingSettings, setSavingSettings] = useState('Save Settings');
  const [submissionYears, setSubmissionYears] = useState([]);
  const [onNewYearCreate, setOnNewYearCreate] = useState(false);
  const [submissionDateMeta, setSubmissionDateMeta] = useState();

  useEffect(() => {
    (async () => {
      const { settings } = await AdminControl.getSettings();
      const submissionsAreActive = await AdminControl.getSubmissionsAreActive();
      if (submissionsAreActive) {
        setSubmissionDateMeta(submissionsAreActive);
      }

      if (settings) {
        settings.enableLongList = new Date(settings.enableLongList);
        settings.enableShortList = new Date(settings.enableShortList);
        settings.newYearStartDate = new Date(settings.newYearStartDate);
        settings.newYearEndDate = new Date(settings.newYearEndDate);
        settings.openAllRegionsToRegionalJurors = new Date(settings.openAllRegionsToRegionalJurors);
        settings.lastReminderNotifiedDate = new Date(settings.lastReminderNotifiedDate);
        settings.year = new Date(settings.year).getUTCFullYear();
        dispatch(settings);
      }
      const { years } = await AdminControl.getYears();
      if (years) {
        const sortedYears = years.sort((a, b) => b - a);
        let highestYear = 0;
        sortedYears.forEach((y) => {
          if (y > highestYear) {
            highestYear = y;
          }
        });
        highestYear += 1;
        sortedYears.unshift(highestYear);
        setSubmissionYears(sortedYears);
      }
    })();
  }, []);

  const initForm = {
    year: false,
    newYearStartDate: new Date(),
    newYearEndDate: new Date(),
    enableLongList: new Date(),
    enableShortList: new Date(),
    openAllRegionsToRegionalJurors: new Date(),
    lockLongOntario: false,
    lockShortOntario: false,
    lockLongQuebec: false,
    lockShortQuebec: false,
    lockLongAtlantic: false,
    lockShortAtlantic: false,
    lockLongPrairies: false,
    lockShortPrairies: false,
    lockLongWestCoast: false,
    lockShortWestCoast: false,
    lockLongPacific: false,
    lockShortPacific: false,
    lockLongNorth: false,
    lockShortNorth: false,
    lockLongCircumpolar: false,
    lockShortCircumpolar: false,
    lastReminderNotifiedDate: new Date()
  };

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  );

  const {
    enableLongList,
    enableShortList,
    newYearStartDate,
    newYearEndDate,
    openAllRegionsToRegionalJurors,
    lockLongOntario,
    lockShortOntario,
    lockLongQuebec,
    lockShortQuebec,
    lockLongAtlantic,
    lockShortAtlantic,
    lockLongPrairies,
    lockShortPrairies,
    lockLongPacific,
    lockShortPacific,
    lockLongNorth,
    lockShortNorth,
    lockLongCircumpolar,
    lockShortCircumpolar,
    lastReminderNotifiedDate
  } = formVal;

  let year = formVal.year;
  year = new Date(`${year}-01-01`).getUTCFullYear()

  const onValChange = (event, name) => {
    const isDate = event instanceof Date;

    if (!isDate) {
      const { name, checked } = event.target;
      dispatch({ [name]: checked });
    } else {
      //console.log('EVENT', event);
      dispatch({ [name]: event });
    }
  };

  const onSelectValChange = (event, name) => {
    const { value } = event.target;
    dispatch({ [name]: value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setSavingSettings('Saving...');

    if (formVal.year) {
      formVal.year = new Date(`${formVal.year}-01-01`).toUTCString();
    }
    const updateSettings = await AdminControl.patchSettings(formVal);
    if (!updateSettings.success) return false;

    const submissionsAreActive = await AdminControl.getSubmissionsAreActive();
    if (submissionsAreActive) {
      setSubmissionDateMeta(submissionsAreActive);
    }

    setSavingSettings('Saved');
    setTimeout(() => setSavingSettings('Save Settings'), 2000);
  };

  const onHandleCreateNewYear = () => {
    let years = [];
    const thisYear = new Date().getUTCFullYear();

    if (submissionYears.length) {
      years = [...submissionYears]
      let highestYear = 0;
      submissionYears.forEach((y) => {
        if (y > highestYear) {
          highestYear = y;
        }
      });
      highestYear += 1;
      years.unshift(highestYear);
    } else {
      years = [thisYear];
    }

    setSubmissionYears(years);
    setOnNewYearCreate(true);
  }

  useEffect(() => {
    if (onNewYearCreate) {
      setTimeout(() => {
        setOnNewYearCreate(false);
      }, 3000);
    }
  }, [onNewYearCreate])

  return (
    <div id="Admin-Settings" className="container">
      <div className="row">
        <div className="col-12 pt-3">
          <h1 className="mb-5">Admin Settings</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12 pb-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="enableLongList">Enable Long List Date</label>
                <DatePicker
                  name="enableLongList"
                  selected={enableLongList}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={(e) => onValChange(e, 'enableLongList')}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <label htmlFor="enableShortList">Enable Short List Date</label>
                <DatePicker
                  name="enableShortList"
                  selected={enableShortList}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={(e) => onValChange(e, 'enableShortList')}
                />
              </div>
            </div>
            <hr style={{margin: `30px 0`, opacity: '0.1' }} />
            <div className="row">
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <label htmlFor="enableShortList">System Year</label><br/>
              {submissionYears && submissionYears.length ? (
                <DropDown
                  name="year"
                  value={year}
                  options={submissionYears}
                  onChange={(e) => onSelectValChange(e, 'year')}
                />
              ) : <p>Loading...</p>}
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
               {submissionDateMeta?.currentYear ? (<p>Submission Year: <br/> {submissionDateMeta?.currentYear}</p>) : null}
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
                <label htmlFor="lastReminderNotifiedDate">Reminder Emails Last Sent:</label>
                <DatePicker
                  name="lastReminderNotifiedDate"
                  selected={lastReminderNotifiedDate}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={(e) => onValChange(e, 'lastReminderNotifiedDate')}
                />
              </div>

                {/*
                <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
                <label htmlFor="enableShortList">Add New Submission Year</label>
                  <Button
                    name="createNewYear"
                    disabled={false}
                    value="+ Create New Year"
                    onPress={onHandleCreateNewYear}
                  />
                  {onNewYearCreate && (<div><p>a new year has been added.</p></div>)}
                  </div>
                */}
            </div>
            <hr style={{margin: `30px 0`, opacity: '0.1' }} />
            <div className="row">
              <div className="form-group col-12 col-sm-6 col-lg-6">
                <label htmlFor="enableLongList">Open Regions to Regional Jurors Date</label>
                <DatePicker
                  name="openAllRegionsToRegionalJurors"
                  selected={openAllRegionsToRegionalJurors}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={(e) => onValChange(e, 'openAllRegionsToRegionalJurors')}
                />
              </div>
            </div>
            <div className="row">
              <h4 style={{marginTop: '50px'}}>New Submission Year Settings</h4>
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <label htmlFor="newYearStartDate">Submissions Open date</label>
                <DatePicker
                  name="newYearStartDate"
                  selected={newYearStartDate}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={(e) => onValChange(e, 'newYearStartDate')}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <label htmlFor="newYearEndDate">Submissions End Date</label>
                <DatePicker
                  name="newYearEndDate"
                  selected={newYearEndDate}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={(e) => onValChange(e, 'newYearEndDate')}
                />
              </div>
            </div>
            <div className="row">
              <h4 style={{marginTop: '50px'}}>Submission Status</h4>
              <div className="form-group col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
                {submissionDateMeta?.submissionsAreActive ? (
                  <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <div style={{borderRadius: '9999em', height: '10px', width: '10px', background: 'green'}} />
                   <div style={{color: 'green', paddingLeft: '10px'}}>Open</div>
                  </div>
                ) : (
                  <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <div style={{borderRadius: '9999em', height: '10px', width: '10px', background: 'red'}} />
                   <div style={{color: 'red', paddingLeft: '10px'}}>Closed</div>
                  </div>
                )}
              </div>
            </div>
            <hr style={{margin: `30px 0`, opacity: '0.1' }} />
            <div className="row mt-5">
              <h4 className="mb-3">Ontario</h4>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockLongOntario">Lock Long List</label>
                <ToggleSwitch
                  name="lockLongOntario"
                  checked={lockLongOntario}
                  onChange={onValChange}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockShortOntario">Lock Short List</label>
                <ToggleSwitch
                  name="lockShortOntario"
                  checked={lockShortOntario}
                  onChange={onValChange}
                />
              </div>
            </div>
            <div className="row mt-5">
              <h4 className="mb-3">Quebec</h4>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockLongQuebec">Lock Long List</label>
                <ToggleSwitch
                  name="lockLongQuebec"
                  checked={lockLongQuebec}
                  onChange={onValChange}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockShortQuebec">Lock Short List</label>
                <ToggleSwitch
                  name="lockShortQuebec"
                  checked={lockShortQuebec}
                  onChange={onValChange}
                />
              </div>
            </div>
            <div className="row mt-5">
              <h4 className="mb-3">Atlantic</h4>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockLongAtlantic">Lock Long List</label>
                <ToggleSwitch
                  name="lockLongAtlantic"
                  checked={lockLongAtlantic}
                  onChange={onValChange}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockShortAtlantic">Lock Short List</label>
                <ToggleSwitch
                  name="lockShortAtlantic"
                  checked={lockShortAtlantic}
                  onChange={onValChange}
                />
              </div>
            </div>
            <div className="row mt-5">
              <h4 className="mb-3">Prairies</h4>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockLongPrairies">Lock Long List</label>
                <ToggleSwitch
                  name="lockLongPrairies"
                  checked={lockLongPrairies}
                  onChange={onValChange}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockShortPrairies">Lock Short List</label>
                <ToggleSwitch
                  name="lockShortPrairies"
                  checked={lockShortPrairies}
                  onChange={onValChange}
                />
              </div>
            </div>
            <div className="row mt-5">
              <h4 className="mb-3">Circumpolar</h4>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockLongCircumpolar">Lock Long List</label>
                <ToggleSwitch
                  name="lockLongCircumpolar"
                  checked={lockLongCircumpolar}
                  onChange={onValChange}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockShortCircumpolar">Lock Short List</label>
                <ToggleSwitch
                  name="lockShortCircumpolar"
                  checked={lockShortCircumpolar}
                  onChange={onValChange}
                />
              </div>
            </div>
            <div className="row mt-5">
              {/* <h4 className="mb-3">West Coast & Yukon</h4> */}
              <h4 className="mb-3">Pacific</h4>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockLongPacific">Lock Long List</label>
                <ToggleSwitch
                  name="lockLongPacific"
                  checked={lockLongPacific}
                  onChange={onValChange}
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4">
                <label htmlFor="lockShortPacific">Lock Short List</label>
                <ToggleSwitch
                  name="lockShortPacific"
                  checked={lockShortPacific}
                  onChange={onValChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-lg mt-5"
              disabled={savingSettings === 'Saving...' || savingSettings === 'Saved'}
            >
              {savingSettings}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
