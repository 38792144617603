import { NominationControl } from './nomination';
import { ENTITY_ENDPOINT, UPLOAD_MEDIA_ENDPOINT } from './constants';
const { v4: uuidv4 } = require('uuid');

export const uploadFile = async (e, entity = {}) => {
    const file = e;
    if (!file) return false;
    
    const data = new FormData();
    const extension = file.name.split('.').pop();
    
    // If title+year is provided (from DigitalImages) then rename file based on artist/title
    // Otherwise randomize the name
    if (entity.title && entity.year && entity.nominationId) {
        const nomination = await NominationControl.getSubmission(entity.nominationId);
        const artistName =
            sanitizeName(`${nomination.submission?.artists[0]?.first_name}-${nomination.submission?.artists[0]?.last_name}`);
        const newFileName = `${artistName}_${sanitizeName(entity.title)}_${sanitizeName(entity.year)}.${extension}`;
        const renamedFile = new File([file], newFileName, { type: file.type });
        data.append('file-attachments-ul', renamedFile);
    } else {
        const uniqueSuffix = Number(Date.now() + Math.round(Math.random() * 1E9) + Math.round(Math.random() * 1E9)).toString(16);
        const randomFileName = `${uuidv4()}${uniqueSuffix}.${extension}`;
        const randomFile = new File([file], randomFileName, { type: file.type });
        data.append('file-attachments-ul', randomFile);
        data.append('originalFileName', file.name);
    }

    return fetch(UPLOAD_MEDIA_ENDPOINT, {
        method: 'POST',
        credentials: 'include',
        body: data
    })
    .then(res => res.json())
    .then(resJson => resJson)
    .catch(error => console.error(error));
}

export const postAttachFile = (fileId, type, entity) => {

    entity.fileId = fileId;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(entity)
    };
  
    return fetch(`${ENTITY_ENDPOINT}/${type}`, options)
        .then(res => res.json())
        .then(resJson => resJson)
        .catch(error => console.error(error));
}

export const patchAttachFile = (entityId, fileId, field) => {

    const entity = {}
    entity[field] = fileId;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(entity)
    };
    
    const type = 'submission';

    return fetch(`${ENTITY_ENDPOINT}/${type}/${entityId}`, options)
        .then(res => res.json())
        .then(resJson => resJson)
        .catch(error => console.error(error));
}

const sanitizeName = (fileName) => {
    return fileName
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '');
};