import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { NominationControl } from '../../../utils/nomination';
import { useAuth } from '../../../utils/auth';
import InfoCircle from '../../../assets/img/info-circle.png';
import PersonFill from '../../../assets/img/person-fill.png';
import Collection from '../../../assets/img/collection.png';
import CollectionPlay from '../../../assets/img/collection-play.png';
import JournalCheck from '../../../assets/img/journal-check.png';

const NominationHeader = ({ step, data, refresh }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [artistData, setArtistData] = useState(null);
  const [nominatorData, setNominatorData] = useState(null);
  const [digitalImagesData, setDigitalImagesData] = useState(null);
  const [mediaWorksData, setMediaWorksData] = useState(null);
  const [disclaimerData, setDisclaimerData] = useState(null);

  /*useEffect(() => {
    handleGetSubmission();
  }, []);*/

  useEffect(() => {
    if (auth.role) handleGetSubmission();
  }, [auth, refresh]);

  useEffect(() => {
    if (step == 1) data(artistData);
    if (step == 2) data(nominatorData);
    if (step == 3) data(digitalImagesData);
    if (step == 4) data(mediaWorksData);
    if (step == 5) data(disclaimerData);
  }, [artistData, nominatorData, digitalImagesData, mediaWorksData, disclaimerData, refresh]);

  const handleGetSubmission = () => {
    NominationControl.getSubmission(id).then(({ submission }) => {
      // Redirect if no submission, or if not in progress
      // Exception for admins so they can edit after submission
      if (!submission || (submission.status !== 'in progress' && submission.status !== null && auth.role !== 'admin')) {
        navigate('/');
      } else if (submission.id) {
        const { primary, secondary } = getArtists(submission);

        setArtistData({
          id: submission.id,
          data: primary,
          collective: secondary,
          collectiveId: submission.collectiveId,
          collective_name: submission.collective_name,
          cv: submission.cv,
          cvFileId: submission.cvFileId,
          bio: submission.bio,
          biographyFileId: submission.biographyFileId,
          headshot: submission.headshot,
          headshotFileId: submission.headshotFileId,
        });

        setNominatorData({
          id: submission.id,
          nominator_first_name: submission.nominator_first_name,
          nominator_last_name: submission.nominator_last_name,
          category: submission.category,
          category_organization: submission.category_organization,
          letter: submission.letter,
        });

        setDigitalImagesData({
          id: submission.id,
          images: submission.images
        });

        setMediaWorksData({
          id: submission.id,
          media: submission.mediaWork
        })

        setDisclaimerData({
          id: submission.id,
          media: submission.mediaWork,
          images: submission.images
        });

        // Set initial status if none
        if (!submission.status) {
          NominationControl.postPatchEntity(
            'PATCH',
            'submission',
            {
              status: 'in progress'
            },
            submission.id
          );
        }
      }
    });
  }

  const handleNominatorClick = (e) => {
    if (!artistData?.data?.first_name) e.preventDefault();
  }
  const handleDigitalClick = (e) => {
    if (!nominatorData?.nominator_first_name) e.preventDefault();
  }
  const handleMediaClick = (e) => {
    if (digitalImagesData?.images?.length < 5) e.preventDefault();
  }

  return (
    <>
      <h1>{t('nomination.header.title')}</h1>
      {auth?.role === 'admin' && <h4 className="mb-4" style={{ marginTop: '-2rem' }}>Editing nomination <strong>{artistData?.data?.first_name} {artistData?.data?.last_name}</strong> as admin</h4>}

      <ul
        className="nav nav-tabs border-primary d-flex flex-row flex-fill justify-content-around mt-lg-5 border-bottom"
        role="tablist"
      >
        <li className="nav-item fw-light" role="presentation">
          <NavLink
            className="nav-link d-flex flex-column justify-content-center align-items-center align-content-center tab-offset"
            role="tab"
            to={`${t('routes:step1')}/${id}`}
          >
            <img src={InfoCircle} height="50" />
            {t('nomination.header.step1')}
            <span className="badge bg-primary rounded-circle mt-2">1</span>
            <i className={`fa position-absolute top-0 start-80 text-secondary ${artistData?.data.address_1 ? 'fa-check' : 'fa-question'}`}></i>
          </NavLink>
        </li>
        <li className="nav-item fw-light" role="presentation">
          <NavLink
            className={`nav-link d-flex flex-column justify-content-center align-items-center align-content-center tab-offset ${!artistData?.data?.first_name ? 'disable-link' : ''}`}
            role="tab"
            to={`${t('routes:step2')}/${id}`}
            onClick={handleNominatorClick}
          >
            <img src={PersonFill} height="50" />
            {t('nomination.header.step2')}
            <span className="badge bg-primary rounded-circle mt-2">2</span>
            <i className={`fa position-absolute top-0 start-80 text-secondary ${nominatorData?.nominator_first_name ? 'fa-check' : 'fa-question'}`}></i>
          </NavLink>
        </li>
        <li className="nav-item fw-light" role="presentation">
          <NavLink
            className={`nav-link d-flex flex-column justify-content-center align-items-center align-content-center tab-offset ${!nominatorData?.nominator_first_name ? 'disable-link' : ''}`}
            role="tab"
            to={`${t('routes:step3')}/${id}`}
            onClick={handleDigitalClick}
          >
            <img src={Collection} height="50" />
            {t('nomination.header.step3')}
            <span className="badge bg-primary rounded-circle mt-2">3</span>
            <i className={`fa position-absolute top-0 start-80 text-secondary ${digitalImagesData?.images?.length >= 5 ? 'fa-check' : 'fa-question'}`}></i>
          </NavLink>
        </li>
        <li className="nav-item fw-light" role="presentation">
          <NavLink
            className={`nav-link d-flex flex-column justify-content-center align-items-center align-content-center tab-offset ${digitalImagesData?.images?.length < 5 ? 'disable-link' : ''}`}
            role="tab"
            to={`${t('routes:step4')}/${id}`}
            onClick={handleMediaClick}
          >
            <img src={CollectionPlay} height="50" />
            {t('nomination.header.step4')}
            <span className="badge bg-primary rounded-circle mt-2">4</span>
            <i className={`fa position-absolute top-0 start-80 text-secondary ${mediaWorksData?.media?.length ? 'fa-check' : 'fa-question'}`}></i>
          </NavLink>
        </li>
        <li className="nav-item fw-light" role="presentation">
          <NavLink
            className={`nav-link d-flex flex-column justify-content-center align-items-center align-content-center tab-offset ${digitalImagesData?.images?.length < 5 ? 'disable-link' : ''}`}
            role="tab"
            to={`${t('routes:step5')}/${id}`}
            onClick={handleMediaClick}
          >
            <img src={JournalCheck} height="50px" />
            {t('nomination.header.step5')}
            <span className="badge bg-primary rounded-circle mt-2">5</span>
            <i className="fa fa-question position-absolute top-0 start-80 text-secondary"></i>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default NominationHeader;


const getArtists = (submission) => {
  if (!Array.isArray(submission.artists)) {
    return { primary: {}, secondary: [] };
  }
  // sort by date created, just in case
  const sorted = submission.artists.sort(function(a,b){
    return new Date(b.createdAt) - new Date(a.createdAt);
  }).reverse();
  // split into primary and secondary
  const hasSecondary = sorted.length > 1;
  const primary = {...sorted.shift()};
  const secondary = hasSecondary ? sorted : [];

  return { primary, secondary }
}
