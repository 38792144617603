import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NominationControl } from '../../../utils/nomination';
import { useAuth } from '../../../utils/auth';

const AdminReport = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [ report, setReport ] = useState(null);

  useEffect(() => {
    NominationControl.getReport().then((report) => {
      setReport(report);
    });
  }, []);

  if (!report) {
    return <div>Loading...</div>
  }

  return (
    <div className="container">
      <div className="mb-5">
        <h1 dangerouslySetInnerHTML={{ __html: t('navigation.submissionsReport')}} />
        <span className="user">
          <i className="fa fa-user" aria-hidden="true"></i>
          {auth.user}
        </span>
      </div>
      <div className="row flex-column-reverse flex-lg-row">
        <div className="col-12 col-lg-8">
          <h3>SUBMISSION COUNTS:</h3>
          <ul>
            <li>
              <strong>Regions: </strong>
              <ul>
                {Object.keys(report.submittedByRegion).map((region) => <li>{region} - {report.submittedByRegion[region]}</li>)}
              </ul>
            </li>
            <li>
              <strong>Total Completed Submissions: {report.totalSubmitted}</strong>
            </li>
            <li>
              <strong>Total In Progress Submissions: {report.totalInProgress}</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminReport;
